import React, {
  useState,
  useContext,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import qs from 'qs';
import LinesEllipsis from 'react-lines-ellipsis';
import { useNavigate } from 'react-router-dom';
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { menuState } from '../../atoms';
import { homeContext, getfindBoard } from '../../lib/utils';
import { ReactComponent as FaqIcon } from '../../assets/images/ic-faq.svg';
import { ReactComponent as UseGuideIcon } from '../../assets/images/ic-useGuide.svg';
import { ReactComponent as DictionaryIcon } from '../../assets/images/ic-dictionary.svg';
import { ReactComponent as PlayIcon } from '../../assets/images/ic-videoPlay.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/ic-rightArrow-w.svg';

const AboutWadiz = () => {
  const { board } = useContext(homeContext);
  const [mode, setMode] = useState('faq');
  const navigate = useNavigate();
  const menuList = useRecoilValue(menuState);
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up('desktop'));
  const faqTitle = useRef('');
  const guideTitle = useRef('');
  const dictionaryTitle = useRef('');

  // console.log('board :', board);

  useEffect(() => {
    faqTitle.current = board?.faq?.lists[0]?.title;
    guideTitle.current = board.guide.lists[0].title;
    dictionaryTitle.current = board?.dictionary?.lists[0]?.title;
  }, [board]);

  // '자주묻는질문' 전체 게시판으로 이동
  const onToFaqBoard = () => {
    const currentMenu = getfindBoard(menuList, board.faq.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // '자주묻는질문' 특정 게시물로 이동
  const onToFaqBoardDetail = useCallback(() => {
    const data = {
      rownum: board.faq.lists[0].rownum,
      search_type: null,
      search_text: null,
      category1_idx: null,
      category2_idx: null,
    };
    const query = qs.stringify(data);
    navigate(
      `/board/${board.faq.board_type}/${board.faq.lists[0].idx}?${query}`,
    );
  }, [board]);

  // '이용가이드' 게시판으로 이동
  const onToGuideBoard = () => {
    const currentMenu = getfindBoard(menuList, board.guide.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // '이용가이드' 특정 게시물로 이동
  const onToGuideBoardDetail = useCallback(() => {
    const data = {
      rownum: board.guide.lists[0].rownum,
      search_type: null,
      search_text: null,
      category1_idx: null,
      category2_idx: null,
    };
    const query = qs.stringify(data);
    navigate(
      `/board/${board.guide.board_type}/${board.guide.lists[0].idx}?${query}`,
    );
  }, [board]);

  // '메이커 클래스' 게시판으로 이동
  const onToDictionaryBoard = () => {
    const currentMenu = getfindBoard(menuList, board.dictionary.board_idx);
    navigate(`/menu/${currentMenu.menu_idx}`);
  };

  // '메이커 클래스' 특정 게시물로 이동
  const onToDictionaryBoardDetail = useCallback(() => {
    const data = {
      rownum: board.dictionary.lists[0].rownum,
      search_type: null,
      search_text: null,
      category1_idx: null,
      category2_idx: null,
    };
    const query = qs.stringify(data);
    navigate(
      `/board/${board.dictionary.board_type}/${board.dictionary.lists[0].idx}?${query}`,
    );
  }, [board]);

  return (
    <Container
      sx={{
        width: isDeskTop ? '610px' : '100%',
        minWidth: isDeskTop ? '550px' : '320px',
        px: isDeskTop ? 0 : '16px',
        mx: isDeskTop ? '5px' : '0',
      }}
    >
      {board === null ? (
        <>...</>
      ) : (
        <>
          <Title
            sx={{
              fontSize: isDeskTop ? '24px' : '18px',
              mb: '16px',
            }}
          >
            와디즈 알아보기
          </Title>
          {/* DeskTop */}
          <FlexBox sx={{ display: isDeskTop ? 'flex' : 'none' }}>
            <Inner
              sx={{
                overflow: 'hidden',
                width: mode === 'faq' && '378px',
                background: mode === 'faq' && '#00C4C4',
              }}
              onMouseEnter={() => setMode('faq')}
            >
              {mode === 'faq' ? (
                <FlexReverseBox
                  sx={{
                    width: '378px',
                    px: '30px',
                    display: mode === 'faq' ? 'flex' : 'none',
                  }}
                >
                  <FlexBox sx={{ mb: '16px' }}>
                    <CategoryText>자주 묻는 질문</CategoryText>
                    <FlexBox
                      sx={{
                        padding: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 'auto',
                        maxHeight: '30px',
                      }}
                      onClick={onToFaqBoard}
                    >
                      <MoreViewText>더보기</MoreViewText>
                      <RightArrowIcon />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox sx={{ justifyContent: 'flex-start' }}>
                    <QIcon>Q</QIcon>
                    <QText onClick={onToFaqBoardDetail}>
                      <LinesEllipsis
                        text={board?.faq?.lists[0]?.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </QText>
                  </FlexBox>
                </FlexReverseBox>
              ) : (
                <FlexReverseBox>
                  <SubTitle>
                    자주
                    <br />
                    묻는 질문
                  </SubTitle>
                  <FaqIcon width={40} height={40} />
                </FlexReverseBox>
              )}
            </Inner>
            <Inner
              sx={{
                overflow: 'hidden',
                width: mode === 'guide' && '378px',
                background: mode === 'guide' && '#00C4C4',
              }}
              onMouseEnter={() => setMode('guide')}
            >
              {mode === 'guide' ? (
                <FlexReverseBox
                  sx={{
                    width: '378px',
                    px: '30px',
                    display: mode === 'guide' ? 'flex' : 'none',
                  }}
                >
                  <FlexBox sx={{ mb: '16px' }}>
                    <CategoryText>이용 가이드</CategoryText>
                    <FlexBox
                      sx={{
                        padding: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 'auto',
                        maxHeight: '30px',
                      }}
                      onClick={onToGuideBoard}
                    >
                      <MoreViewText>더보기</MoreViewText>
                      <RightArrowIcon />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox sx={{ justifyContent: 'flex-start' }}>
                    <QIcon>Q</QIcon>
                    <QText onClick={onToGuideBoardDetail}>
                      <LinesEllipsis
                        text={board?.guide?.lists[0]?.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </QText>
                  </FlexBox>
                </FlexReverseBox>
              ) : (
                <FlexReverseBox>
                  <SubTitle>
                    이용
                    <br />
                    가이드
                  </SubTitle>
                  <UseGuideIcon width={40} height={40} />
                </FlexReverseBox>
              )}
            </Inner>
            <Inner
              sx={{
                overflow: 'hidden',
                width: mode === 'dictionary' && '378px',
                background: mode === 'dictionary' && '#00C4C4',
              }}
              onMouseEnter={() => setMode('dictionary')}
            >
              {mode === 'dictionary' ? (
                <FlexReverseBox
                  sx={{
                    width: '378px',
                    px: '30px',
                    display: mode === 'dictionary' ? 'flex' : 'none',
                  }}
                >
                  <FlexBox sx={{ mb: '16px' }}>
                    <CategoryText>메이커 클래스</CategoryText>
                    <FlexBox
                      sx={{
                        padding: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 'auto',
                        maxHeight: '30px',
                      }}
                      onClick={onToDictionaryBoard}
                    >
                      <MoreViewText>더보기</MoreViewText>
                      <RightArrowIcon />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox sx={{ justifyContent: 'flex-start' }}>
                    <QIcon>
                      <PlayIcon width={24} height={24} />
                    </QIcon>
                    <QText onClick={onToDictionaryBoardDetail}>
                      <LinesEllipsis
                        text={board?.dictionary?.lists[0]?.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </QText>
                  </FlexBox>
                </FlexReverseBox>
              ) : (
                <FlexReverseBox>
                  <SubTitle>
                    메이커
                    <br />
                    클래스
                  </SubTitle>
                  <DictionaryIcon width={40} height={40} />
                </FlexReverseBox>
              )}
            </Inner>
          </FlexBox>
          {/* Mobile 56->140 */}
          {/* ---------------------------------------- */}
          <Box
            sx={{
              display: isDeskTop ? 'none' : 'block',
              height: '268px',
            }}
          >
            <Inner
              sx={{
                width: '100%',
                height: mode === 'faq' ? '140px' : '56px',
                background: mode === 'faq' && '#00C4C4',
                mb: '8px',
              }}
              onMouseEnter={() => setMode('faq')}
            >
              {mode === 'faq' ? (
                <FlexReverseBox
                  sx={{
                    px: '16px',
                    minWidth: '320px',
                    display: mode === 'faq' ? 'flex' : 'none',
                  }}
                >
                  <FlexBox sx={{ mb: '16px' }}>
                    <CategoryText>자주 묻는 질문</CategoryText>
                    <FlexBox
                      sx={{
                        padding: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 'auto',
                        maxHeight: '30px',
                      }}
                      onClick={onToFaqBoard}
                    >
                      <MoreViewText>더보기</MoreViewText>
                      <RightArrowIcon />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <QIcon>Q</QIcon>
                    <QText
                      sx={{
                        height: '50px',
                        width: '90%',
                        minWidth: '210px',
                        fontSize: '18px',
                      }}
                      onClick={onToFaqBoardDetail}
                    >
                      <LinesEllipsis
                        text={board?.faq?.lists[0]?.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </QText>
                  </FlexBox>
                </FlexReverseBox>
              ) : (
                <FlexBox sx={{ height: '100%', px: '16px' }}>
                  <SubTitle>자주 묻는 질문</SubTitle>
                  <FaqIcon width={32} height={32} />
                </FlexBox>
              )}
            </Inner>
            <Inner
              sx={{
                width: '100%',
                height: mode === 'guide' ? '140px' : '56px',
                background: mode === 'guide' && '#00C4C4',
                mb: '8px',
              }}
              onMouseEnter={() => setMode('guide')}
            >
              {mode === 'guide' ? (
                <FlexReverseBox
                  sx={{
                    px: '16px',
                    display: mode === 'guide' ? 'flex' : 'none',
                  }}
                >
                  <FlexBox sx={{ mb: '16px' }}>
                    <CategoryText>이용 가이드</CategoryText>
                    <FlexBox
                      sx={{
                        padding: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 'auto',
                        maxHeight: '30px',
                      }}
                      onClick={onToGuideBoard}
                    >
                      <MoreViewText>더보기</MoreViewText>
                      <RightArrowIcon />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <QIcon>Q</QIcon>
                    <QText
                      sx={{
                        height: '50px',
                        width: '90%',
                        minWidth: '210px',
                        fontSize: '18px',
                      }}
                      onClick={onToGuideBoardDetail}
                    >
                      <LinesEllipsis
                        text={board?.guide?.lists[0]?.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </QText>
                  </FlexBox>
                </FlexReverseBox>
              ) : (
                <FlexBox sx={{ height: '100%', px: '16px' }}>
                  <SubTitle>이용 가이드</SubTitle>
                  <UseGuideIcon width={32} height={32} />
                </FlexBox>
              )}
            </Inner>
            <Inner
              sx={{
                width: '100%',
                height: mode === 'dictionary' ? '140px' : '56px',
                background: mode === 'dictionary' && '#00C4C4',
                mb: '8px',
              }}
              onMouseEnter={() => setMode('dictionary')}
            >
              {mode === 'dictionary' ? (
                <FlexReverseBox
                  sx={{
                    px: '16px',
                    display: mode === 'dictionary' ? 'flex' : 'none',
                  }}
                >
                  <FlexBox sx={{ mb: '16px' }}>
                    <CategoryText>메이커 클래스</CategoryText>
                    <FlexBox
                      sx={{
                        padding: 0,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: 'auto',
                        maxHeight: '30px',
                      }}
                      onClick={onToDictionaryBoard}
                    >
                      <MoreViewText>더보기</MoreViewText>
                      <RightArrowIcon />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <QIcon>
                      <PlayIcon width={20} height={20} />
                    </QIcon>
                    <QText
                      sx={{
                        height: '50px',
                        width: '90%',
                        minWidth: '210px',
                        fontSize: '18px',
                      }}
                      onClick={onToDictionaryBoardDetail}
                    >
                      <LinesEllipsis
                        text={board?.dictionary?.lists[0]?.title}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </QText>
                  </FlexBox>
                </FlexReverseBox>
              ) : (
                <FlexBox sx={{ height: '100%', px: '16px' }}>
                  <SubTitle>메이커 클래스</SubTitle>
                  <DictionaryIcon width={32} height={32} />
                </FlexBox>
              )}
            </Inner>
          </Box>
        </>
      )}
    </Container>
  );
};

const Container = styled(Box)({
  position: 'relative',
});
const Title = styled(Box)({
  fontSize: '24px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '34px',
  marginBottom: '16px',
});
const FlexBox = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: 0,
  borderRadius: '8px',
});
const FlexReverseBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: 0,
  borderRadius: '8px',
});
const Inner = styled(Box)({
  textAlign: 'center',
  cursor: 'pointer',
  height: '168px',
  minWidth: '104px',
  backgroundColor: '#E7F9F9',
  border: 0,
  borderRadius: '8px',
  transition: '.3s ease-in-out',
  overflow: 'hidden',
});
const SubTitle = styled(Box)({
  fontSize: '18px',
  fontWeight: 700,
  color: '#212529',
  lineHeight: '28px',
});
const CategoryText = styled(Box)({
  maxHeight: '28px',
  overflow: 'hidden',
  color: '#FFFFFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '28px',
});
const MoreViewText = styled(Box)({
  marginRight: '8px',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
});
const QIcon = styled(Box)({
  minWidth: '48px',
  height: '48px',
  marginRight: '15px',
  border: 0,
  borderRadius: '8px',
  backgroundColor: '#00B2B2',
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const QText = styled(Box)({
  height: '60px',
  width: '300px',
  color: '#FFFFFF',
  fontSize: '22px',
  fontWeight: 700,
  lineHeight: '30px',
  textAlign: 'left',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export default AboutWadiz;
